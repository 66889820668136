// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.graph {
  font-weight: normal;
  font-size: 15px;
}

.tooltip {
  background-color: white;
  border-radius: 3px;
  padding: 3px;
  opacity: 0.8;
}

.tooltip_timestamp {
  font-weight: 600;
  margin: 5px;
}

.tooltip_value {
  margin: 5px;
}`, "",{"version":3,"sources":["webpack://./src/overview/spotlist/spot/forecast/MswForecastGraph.scss"],"names":[],"mappings":"AAAA;EACE,mBAAA;EACA,eAAA;AACF;;AAEA;EACE,uBAAA;EACA,kBAAA;EACA,YAAA;EACA,YAAA;AACF;;AAEA;EACE,gBAAA;EACA,WAAA;AACF;;AAEA;EACE,WAAA;AACF","sourcesContent":[".graph {\n  font-weight: normal;\n  font-size: 15px;\n}\n\n.tooltip {\n  background-color: white;\n  border-radius: 3px;\n  padding: 3px;\n  opacity: 0.8;\n}\n\n.tooltip_timestamp {\n  font-weight: 600;\n  margin: 5px;\n}\n\n.tooltip_value {\n  margin: 5px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
