// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.loader {
  align-self: center;
  width: 70px;
  height: 50px;
  border-radius: 50%;
  overflow: hidden;
  margin: auto;
}

.wave {
  position: relative;
  width: 100%;
  height: 100%;
  background: #2C74B3;
  border-radius: 50%;
  box-shadow: inset 0 0 50px 0 rgba(0, 0, 0, 0);
}

.wave:before, .wave:after {
  content: "";
  position: absolute;
  left: 50%;
  width: 200%;
  height: 200%;
}

.wave:before {
  border-radius: 45%;
  background: rgb(255, 255, 255);
  animation: animate 5s linear infinite;
}

.wave:after {
  border-radius: 40%;
  background: rgba(255, 255, 255, 0.5);
  animation: animate 10s linear infinite;
}

@keyframes animate {
  0% {
    transform: translate(-50%, -75%) rotate(0deg);
  }
  100% {
    transform: translate(-50%, -75%) rotate(360deg);
  }
}`, "",{"version":3,"sources":["webpack://./src/loader/MswLoader.scss"],"names":[],"mappings":"AAAA;EACE,kBAAA;EACA,WAAA;EACA,YAAA;EACA,kBAAA;EACA,gBAAA;EACA,YAAA;AACF;;AAEA;EACE,kBAAA;EACA,WAAA;EACA,YAAA;EACA,mBAAA;EACA,kBAAA;EACA,6CAAA;AACF;;AAEA;EACE,WAAA;EACA,kBAAA;EACA,SAAA;EACA,WAAA;EACA,YAAA;AACF;;AAEA;EACE,kBAAA;EACA,8BAAA;EACA,qCAAA;AACF;;AAEA;EACE,kBAAA;EACA,oCAAA;EACA,sCAAA;AACF;;AAEA;EACE;IACE,6CAAA;EACF;EACA;IACE,+CAAA;EACF;AACF","sourcesContent":[".loader {\n  align-self: center;\n  width: 70px;\n  height: 50px;\n  border-radius: 50%;\n  overflow: hidden;\n  margin: auto;\n}\n\n.wave {\n  position: relative;\n  width: 100%;\n  height: 100%;\n  background: #2C74B3;\n  border-radius: 50%;\n  box-shadow: inset 0 0 50px 0 rgba(0, 0, 0, 0);\n}\n\n.wave:before, .wave:after {\n  content: '';\n  position: absolute;\n  left: 50%;\n  width: 200%;\n  height: 200%;\n}\n\n.wave:before {\n  border-radius: 45%;\n  background: rgba(255, 255, 255, 1);\n  animation: animate 5s linear infinite;\n}\n\n.wave:after {\n  border-radius: 40%;\n  background: rgba(255, 255, 255, 0.5);\n  animation: animate 10s linear infinite;\n}\n\n@keyframes animate {\n  0% {\n    transform: translate(-50%, -75%) rotate(0deg);\n  }\n  100% {\n    transform: translate(-50%, -75%) rotate(360deg);\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
