// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.tableHeader {
  flex: 1 1;
  display: flex;
}

.tableHeaderCol {
  flex: 1 1;
}

.tableHeaderContainer {
  padding: 0 10px;
  margin: 0 10px;
  display: flex;
}

.spotsContainer {
  flex: 1 1;
  width: 700px;
  margin: 0 auto auto auto;
}

.doubleCol {
  flex: 2 1;
}

.hide {
  visibility: hidden;
}

.collapsibleIcon {
  margin: 0 10px;
  flex: 0.03 1;
}

.collapsibleIcon > img {
  width: 100%;
  cursor: pointer;
}

.item.dragging {
  opacity: 0.6;
}

@media screen and (max-width: 719px) {
  .tableHeaderContainer {
    display: none;
  }
  .spotsContainer {
    width: 100%;
    padding: 5px;
  }
}`, "",{"version":3,"sources":["webpack://./src/overview/spotlist/SpotList.scss","webpack://./src/ReactiveMixins.scss"],"names":[],"mappings":"AAEA;EACE,SAAA;EACA,aAAA;AADF;;AAIA;EACE,SAAA;AADF;;AAIA;EACE,eAAA;EACA,cAAA;EACA,aAAA;AADF;;AAIA;EACE,SAAA;EACA,YAAA;EACA,wBAAA;AADF;;AAIA;EACE,SAAA;AADF;;AAIA;EACE,kBAAA;AADF;;AAIA;EACE,cAAA;EACA,YAAA;AADF;;AAIA;EACE,WAAA;EACA,eAAA;AADF;;AAIA;EACE,YAAA;AADF;;AClCE;EDuCA;IACE,aAAA;EADF;EAIA;IACE,WAAA;IACA,YAAA;EAFF;AACF","sourcesContent":["@import \"../../ReactiveMixins\";\n\n.tableHeader {\n  flex: 1;\n  display: flex;\n}\n\n.tableHeaderCol {\n  flex: 1;\n}\n\n.tableHeaderContainer {\n  padding: 0 10px;\n  margin: 0 10px;\n  display: flex;\n}\n\n.spotsContainer {\n  flex: 1;\n  width: 700px;\n  margin: 0 auto auto auto;\n}\n\n.doubleCol {\n  flex: 2;\n}\n\n.hide {\n  visibility: hidden;\n}\n\n.collapsibleIcon {\n  margin: 0 10px;\n  flex: 0.03;\n}\n\n.collapsibleIcon > img {\n  width: 100%;\n  cursor: pointer;\n}\n\n.item.dragging {\n  opacity: 0.6;\n}\n\n@include small {\n  .tableHeaderContainer {\n    display: none;\n  }\n\n  .spotsContainer {\n    width: 100%;\n    padding: 5px;\n  }\n}","$screen-medium-min: 720px;\n$screen-large-min: 1000px;\n\n$screen-small-max: $screen-medium-min - 1;\n$screen-medium-max: $screen-large-min - 1;\n\n@mixin small {\n  @media screen and (max-width: $screen-small-max) {\n    @content;\n  }\n}\n\n@mixin medium {\n  @media screen and (min-width: $screen-medium-min) and (max-width: $screen-medium-max) {\n    @content;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
