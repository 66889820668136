// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.msw-button {
  background: white;
  color: #3bbae8;
  font-weight: normal;
  font-family: Arial, sans-serif;
  font-size: 15px !important;
  border: 1px solid #3bbae8;
  border-radius: 3px;
  text-decoration: none;
  padding: 3px;
  min-width: 60px;
  margin: 10px;
}

a {
  color: #3bbae8;
}`, "",{"version":3,"sources":["webpack://./src/index.scss"],"names":[],"mappings":"AAGA;EACE,iBAAA;EACA,cALS;EAMT,mBAAA;EACA,8BAAA;EACA,0BAAA;EACA,yBAAA;EACA,kBAAA;EACA,qBAAA;EACA,YAAA;EACA,eAAA;EACA,YAAA;AAFF;;AAKA;EACE,cAlBS;AAgBX","sourcesContent":["$msw-blue: #3bbae8;\n$msw-blue-secondary: #2473db;\n\n.msw-button {\n  background: white;\n  color: $msw-blue;\n  font-weight: normal;\n  font-family: Arial, sans-serif;\n  font-size: 15px !important;\n  border: 1px solid $msw-blue;\n  border-radius: 3px;\n  text-decoration: none;\n  padding: 3px;\n  min-width: 60px;\n  margin: 10px\n}\n\na {\n  color: $msw-blue;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
