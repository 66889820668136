// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.msw-button {
  background: white;
  color: #3bbae8;
  font-weight: normal;
  font-family: Arial, sans-serif;
  font-size: 15px !important;
  border: 1px solid #3bbae8;
  border-radius: 3px;
  text-decoration: none;
  padding: 3px;
  min-width: 60px;
  margin: 10px;
}

a {
  color: #3bbae8;
}

.Footer {
  margin-top: 30px;
  padding: 1.25rem;
  color: white;
  background-color: #3bbae8;
}
.Footer .Footer_list {
  list-style: none;
  padding: 0;
  margin: 0 2rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
@media screen and (max-width: 719px) {
  .Footer .Footer_list {
    flex-direction: column;
  }
}
@media screen and (min-width: 720px) and (max-width: 999px) {
  .Footer .Footer_list {
    flex-direction: column;
  }
}
.Footer .Footer_list li {
  margin: 1rem;
  min-width: 14rem;
}
.Footer .Footer_list a {
  color: white;
}`, "",{"version":3,"sources":["webpack://./src/index.scss","webpack://./src/footer/MswFooter.scss","webpack://./src/ReactiveMixins.scss"],"names":[],"mappings":"AAGA;EACE,iBAAA;EACA,cALS;EAMT,mBAAA;EACA,8BAAA;EACA,0BAAA;EACA,yBAAA;EACA,kBAAA;EACA,qBAAA;EACA,YAAA;EACA,eAAA;EACA,YAAA;ACFF;;ADKA;EACE,cAlBS;ACgBX;;AAbA;EACE,gBAAA;EACA,gBAAA;EACA,YAAA;EACA,yBDPS;ACuBX;AAdE;EACE,gBAAA;EACA,UAAA;EACA,cAAA;EACA,aAAA;EACA,8BAAA;EACA,mBAAA;AAgBJ;ACxBE;EDEA;IASI,sBAAA;EAiBJ;AACF;ACvBE;EDJA;IAaI,sBAAA;EAkBJ;AACF;AAhBI;EACE,YAAA;EACA,gBAAA;AAkBN;AAfI;EACE,YAAA;AAiBN","sourcesContent":["$msw-blue: #3bbae8;\n$msw-blue-secondary: #2473db;\n\n.msw-button {\n  background: white;\n  color: $msw-blue;\n  font-weight: normal;\n  font-family: Arial, sans-serif;\n  font-size: 15px !important;\n  border: 1px solid $msw-blue;\n  border-radius: 3px;\n  text-decoration: none;\n  padding: 3px;\n  min-width: 60px;\n  margin: 10px\n}\n\na {\n  color: $msw-blue;\n}","@import \"../ReactiveMixins\";\n@import \"../index\";\n\n.Footer {\n  margin-top: 30px;\n  padding: 1.25rem;\n  color: white;\n  background-color: $msw-blue;\n\n  .Footer_list {\n    list-style: none;\n    padding: 0;\n    margin: 0 2rem;\n    display: flex;\n    justify-content: space-between;\n    align-items: center;\n\n    @include small {\n      flex-direction: column;\n    }\n\n    @include medium {\n      flex-direction: column;\n    }\n\n    li {\n      margin: 1rem;\n      min-width: 14rem;\n    }\n\n    a {\n      color: white;\n    }\n  }\n}","$screen-medium-min: 720px;\n$screen-large-min: 1000px;\n\n$screen-small-max: $screen-medium-min - 1;\n$screen-medium-max: $screen-large-min - 1;\n\n@mixin small {\n  @media screen and (max-width: $screen-small-max) {\n    @content;\n  }\n}\n\n@mixin medium {\n  @media screen and (min-width: $screen-medium-min) and (max-width: $screen-medium-max) {\n    @content;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
