// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.success-msg {
  color: green;
  margin-top: 24px;
}

.link-to-login {
  margin-top: 20px;
  display: block;
  text-align: center;
  color: black;
}`, "",{"version":3,"sources":["webpack://./src/user/forgot-password/MswForgotPassword.scss"],"names":[],"mappings":"AAAA;EACE,YAAA;EACA,gBAAA;AACF;;AAEA;EACE,gBAAA;EACA,cAAA;EACA,kBAAA;EACA,YAAA;AACF","sourcesContent":[".success-msg {\n  color: green;\n  margin-top: 24px;\n}\n\n.link-to-login {\n  margin-top: 20px;\n  display: block;\n  text-align: center;\n  color: black;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
