// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.miniGraph {
  flex: 1 1;
  width: 100%;
  max-width: 350px;
  margin: auto;
}

.forecastContainer {
  font-size: 20px;
  flex: 2 1;
  align-items: center;
  display: flex;
  column-gap: 5px;
  width: 100%;
}`, "",{"version":3,"sources":["webpack://./src/overview/spotlist/spot/miniForecast/MswMiniForecast.scss"],"names":[],"mappings":"AAAA;EACE,SAAA;EACA,WAAA;EACA,gBAAA;EACA,YAAA;AACF;;AAEA;EACE,eAAA;EACA,SAAA;EACA,mBAAA;EACA,aAAA;EACA,eAAA;EACA,WAAA;AACF","sourcesContent":[".miniGraph {\n  flex: 1;\n  width: 100%;\n  max-width: 350px;\n  margin: auto;\n}\n\n.forecastContainer {\n  font-size: 20px;\n  flex: 2;\n  align-items: center;\n  display: flex;\n  column-gap: 5px;\n  width: 100%;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
