// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.msw-button {
  background: white;
  color: #3bbae8;
  font-weight: normal;
  font-family: Arial, sans-serif;
  font-size: 15px !important;
  border: 1px solid #3bbae8;
  border-radius: 3px;
  text-decoration: none;
  padding: 3px;
  min-width: 60px;
  margin: 10px;
}

a {
  color: #3bbae8;
}

.App {
  text-align: center;
  display: flex;
  min-height: 100vh;
  flex-direction: column;
}

.surfspots {
  column-gap: 40px;
  margin: auto;
  flex-grow: 1;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  flex-flow: row wrap;
  color: #3bbae8;
}
@media screen and (max-width: 719px) {
  .surfspots {
    flex-direction: column;
    width: 100%;
  }
}`, "",{"version":3,"sources":["webpack://./src/index.scss","webpack://./src/overview/MswOverviewPage.scss","webpack://./src/ReactiveMixins.scss"],"names":[],"mappings":"AAGA;EACE,iBAAA;EACA,cALS;EAMT,mBAAA;EACA,8BAAA;EACA,0BAAA;EACA,yBAAA;EACA,kBAAA;EACA,qBAAA;EACA,YAAA;EACA,eAAA;EACA,YAAA;ACFF;;ADKA;EACE,cAlBS;ACgBX;;AAbA;EACE,kBAAA;EACA,aAAA;EACA,iBAAA;EACA,sBAAA;AAgBF;;AAbA;EACE,gBAAA;EACA,YAAA;EACA,YAAA;EACA,aAAA;EACA,mBAAA;EACA,6BAAA;EACA,mBAAA;EACA,cDlBS;ACkCX;AC3BE;EDGF;IAWI,sBAAA;IACA,WAAA;EAiBF;AACF","sourcesContent":["$msw-blue: #3bbae8;\n$msw-blue-secondary: #2473db;\n\n.msw-button {\n  background: white;\n  color: $msw-blue;\n  font-weight: normal;\n  font-family: Arial, sans-serif;\n  font-size: 15px !important;\n  border: 1px solid $msw-blue;\n  border-radius: 3px;\n  text-decoration: none;\n  padding: 3px;\n  min-width: 60px;\n  margin: 10px\n}\n\na {\n  color: $msw-blue;\n}","@import \"../ReactiveMixins\";\n@import \"../index\";\n\n.App {\n  text-align: center;\n  display: flex;\n  min-height: 100vh;\n  flex-direction: column;\n}\n\n.surfspots {\n  column-gap: 40px;\n  margin: auto;\n  flex-grow: 1;\n  display: flex;\n  flex-direction: row;\n  justify-content: space-evenly;\n  flex-flow: row wrap;\n  color: $msw-blue;\n\n  @include small {\n    flex-direction: column;\n    width: 100%;\n  }\n}\n","$screen-medium-min: 720px;\n$screen-large-min: 1000px;\n\n$screen-small-max: $screen-medium-min - 1;\n$screen-medium-max: $screen-large-min - 1;\n\n@mixin small {\n  @media screen and (max-width: $screen-small-max) {\n    @content;\n  }\n}\n\n@mixin medium {\n  @media screen and (min-width: $screen-medium-min) and (max-width: $screen-medium-max) {\n    @content;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
