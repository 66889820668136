// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.error-message {
  background: lightcoral;
  padding: 1rem;
  margin: 1rem 0;
  border-radius: 0.5rem;
}

.error-message > p {
  margin: 0;
}`, "",{"version":3,"sources":["webpack://./src/user/signup/MswSignUp.scss"],"names":[],"mappings":"AAAA;EACE,sBAAA;EACA,aAAA;EACA,cAAA;EACA,qBAAA;AACF;;AAEA;EACE,SAAA;AACF","sourcesContent":[".error-message {\n  background: lightcoral;\n  padding: 1rem;\n  margin: 1rem 0;\n  border-radius: 0.5rem;\n}\n\n.error-message > p {\n  margin: 0;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
