/* tslint:disable */
/* eslint-disable */
/**
 * Msw Api
 * Msw Api
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from './configuration';
import type { AxiosPromise, AxiosInstance, RawAxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
import type { RequestArgs } from './base';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, BaseAPI, RequiredError, operationServerMap } from './base';

/**
 * 
 * @export
 * @interface AddPrivateSpotRequest
 */
export interface AddPrivateSpotRequest {
    /**
     * 
     * @type {ApiSpot}
     * @memberof AddPrivateSpotRequest
     */
    'spot': ApiSpot;
    /**
     * 
     * @type {number}
     * @memberof AddPrivateSpotRequest
     */
    'position': number;
}
/**
 * 
 * @export
 * @interface ApiForecast
 */
export interface ApiForecast {
    /**
     * 
     * @type {string}
     * @memberof ApiForecast
     */
    'timestamp': string;
    /**
     * 
     * @type {Array<ApiForecastLineEntry>}
     * @memberof ApiForecast
     */
    'measuredData': Array<ApiForecastLineEntry>;
    /**
     * 
     * @type {Array<ApiForecastLineEntry>}
     * @memberof ApiForecast
     */
    'median': Array<ApiForecastLineEntry>;
    /**
     * 
     * @type {Array<ApiForecastLineEntry>}
     * @memberof ApiForecast
     */
    'twentyFivePercentile': Array<ApiForecastLineEntry>;
    /**
     * 
     * @type {Array<ApiForecastLineEntry>}
     * @memberof ApiForecast
     */
    'seventyFivePercentile': Array<ApiForecastLineEntry>;
    /**
     * 
     * @type {Array<ApiForecastLineEntry>}
     * @memberof ApiForecast
     */
    'max': Array<ApiForecastLineEntry>;
    /**
     * 
     * @type {Array<ApiForecastLineEntry>}
     * @memberof ApiForecast
     */
    'min': Array<ApiForecastLineEntry>;
}
/**
 * 
 * @export
 * @interface ApiForecastLineEntry
 */
export interface ApiForecastLineEntry {
    /**
     * 
     * @type {string}
     * @memberof ApiForecastLineEntry
     */
    'timestamp': string;
    /**
     * 
     * @type {number}
     * @memberof ApiForecastLineEntry
     */
    'flow': number;
}
/**
 * 
 * @export
 * @interface ApiSample
 */
export interface ApiSample {
    /**
     * 
     * @type {string}
     * @memberof ApiSample
     */
    'timestamp': string;
    /**
     * 
     * @type {number}
     * @memberof ApiSample
     */
    'temperature'?: number;
    /**
     * 
     * @type {number}
     * @memberof ApiSample
     */
    'flow': number;
}
/**
 * 
 * @export
 * @interface ApiSpot
 */
export interface ApiSpot {
    /**
     * 
     * @type {string}
     * @memberof ApiSpot
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiSpot
     */
    'name': string;
    /**
     * 
     * @type {number}
     * @memberof ApiSpot
     */
    'stationId': number;
    /**
     * 
     * @type {string}
     * @memberof ApiSpot
     */
    'spotType': ApiSpotSpotTypeEnum;
    /**
     * 
     * @type {boolean}
     * @memberof ApiSpot
     */
    'isPublic': boolean;
    /**
     * 
     * @type {number}
     * @memberof ApiSpot
     */
    'minFlow': number;
    /**
     * 
     * @type {number}
     * @memberof ApiSpot
     */
    'maxFlow': number;
}

export const ApiSpotSpotTypeEnum = {
    RiverSurf: 'RIVER_SURF',
    BungeeSurf: 'BUNGEE_SURF'
} as const;

export type ApiSpotSpotTypeEnum = typeof ApiSpotSpotTypeEnum[keyof typeof ApiSpotSpotTypeEnum];

/**
 * 
 * @export
 * @interface ApiSpotInformation
 */
export interface ApiSpotInformation {
    /**
     * 
     * @type {string}
     * @memberof ApiSpotInformation
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiSpotInformation
     */
    'name': string;
    /**
     * 
     * @type {number}
     * @memberof ApiSpotInformation
     */
    'stationId': number;
    /**
     * 
     * @type {string}
     * @memberof ApiSpotInformation
     */
    'spotType': ApiSpotInformationSpotTypeEnum;
    /**
     * 
     * @type {boolean}
     * @memberof ApiSpotInformation
     */
    'isPublic': boolean;
    /**
     * 
     * @type {number}
     * @memberof ApiSpotInformation
     */
    'minFlow': number;
    /**
     * 
     * @type {number}
     * @memberof ApiSpotInformation
     */
    'maxFlow': number;
    /**
     * 
     * @type {ApiSample}
     * @memberof ApiSpotInformation
     */
    'currentSample': ApiSample;
    /**
     * 
     * @type {ApiForecast}
     * @memberof ApiSpotInformation
     */
    'forecast': ApiForecast;
}

export const ApiSpotInformationSpotTypeEnum = {
    RiverSurf: 'RIVER_SURF',
    BungeeSurf: 'BUNGEE_SURF'
} as const;

export type ApiSpotInformationSpotTypeEnum = typeof ApiSpotInformationSpotTypeEnum[keyof typeof ApiSpotInformationSpotTypeEnum];

/**
 * 
 * @export
 * @interface ApiSpotInformationList
 */
export interface ApiSpotInformationList {
    /**
     * 
     * @type {Array<ApiSpotInformation>}
     * @memberof ApiSpotInformationList
     */
    'riverSurfSpots': Array<ApiSpotInformation>;
    /**
     * 
     * @type {Array<ApiSpotInformation>}
     * @memberof ApiSpotInformationList
     */
    'bungeeSurfSpots': Array<ApiSpotInformation>;
}

/**
 * ForecastApi - axios parameter creator
 * @export
 */
export const ForecastApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get Forecast for specific Station
         * @param {number} stationId The id of the station to get the sample from.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getForecast: async (stationId: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'stationId' is not null or undefined
            assertParamExists('getForecast', 'stationId', stationId)
            const localVarPath = `/forecast/{stationId}`
                .replace(`{${"stationId"}}`, encodeURIComponent(String(stationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ForecastApi - functional programming interface
 * @export
 */
export const ForecastApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ForecastApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Get Forecast for specific Station
         * @param {number} stationId The id of the station to get the sample from.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getForecast(stationId: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiForecast>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getForecast(stationId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ForecastApi.getForecast']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * ForecastApi - factory interface
 * @export
 */
export const ForecastApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ForecastApiFp(configuration)
    return {
        /**
         * 
         * @summary Get Forecast for specific Station
         * @param {number} stationId The id of the station to get the sample from.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getForecast(stationId: number, options?: any): AxiosPromise<ApiForecast> {
            return localVarFp.getForecast(stationId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ForecastApi - object-oriented interface
 * @export
 * @class ForecastApi
 * @extends {BaseAPI}
 */
export class ForecastApi extends BaseAPI {
    /**
     * 
     * @summary Get Forecast for specific Station
     * @param {number} stationId The id of the station to get the sample from.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ForecastApi
     */
    public getForecast(stationId: number, options?: RawAxiosRequestConfig) {
        return ForecastApiFp(this.configuration).getForecast(stationId, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * SampleApi - axios parameter creator
 * @export
 */
export const SampleApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get current Flow and Temperature of a specific Station
         * @param {number} stationId The id of the station to get the sample from.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCurrentSample: async (stationId: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'stationId' is not null or undefined
            assertParamExists('getCurrentSample', 'stationId', stationId)
            const localVarPath = `/sample/current/{stationId}`
                .replace(`{${"stationId"}}`, encodeURIComponent(String(stationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Samples from last 40 Days for this station.
         * @param {number} stationId The id of the station to get the sample from.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLast40DaysSamples: async (stationId: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'stationId' is not null or undefined
            assertParamExists('getLast40DaysSamples', 'stationId', stationId)
            const localVarPath = `/sample/last40Days/{stationId}`
                .replace(`{${"stationId"}}`, encodeURIComponent(String(stationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SampleApi - functional programming interface
 * @export
 */
export const SampleApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = SampleApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Get current Flow and Temperature of a specific Station
         * @param {number} stationId The id of the station to get the sample from.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCurrentSample(stationId: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiSample>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCurrentSample(stationId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SampleApi.getCurrentSample']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get Samples from last 40 Days for this station.
         * @param {number} stationId The id of the station to get the sample from.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getLast40DaysSamples(stationId: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ApiSample>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getLast40DaysSamples(stationId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SampleApi.getLast40DaysSamples']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * SampleApi - factory interface
 * @export
 */
export const SampleApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = SampleApiFp(configuration)
    return {
        /**
         * 
         * @summary Get current Flow and Temperature of a specific Station
         * @param {number} stationId The id of the station to get the sample from.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCurrentSample(stationId: number, options?: any): AxiosPromise<ApiSample> {
            return localVarFp.getCurrentSample(stationId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Samples from last 40 Days for this station.
         * @param {number} stationId The id of the station to get the sample from.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLast40DaysSamples(stationId: number, options?: any): AxiosPromise<Array<ApiSample>> {
            return localVarFp.getLast40DaysSamples(stationId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * SampleApi - object-oriented interface
 * @export
 * @class SampleApi
 * @extends {BaseAPI}
 */
export class SampleApi extends BaseAPI {
    /**
     * 
     * @summary Get current Flow and Temperature of a specific Station
     * @param {number} stationId The id of the station to get the sample from.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SampleApi
     */
    public getCurrentSample(stationId: number, options?: RawAxiosRequestConfig) {
        return SampleApiFp(this.configuration).getCurrentSample(stationId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Samples from last 40 Days for this station.
     * @param {number} stationId The id of the station to get the sample from.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SampleApi
     */
    public getLast40DaysSamples(stationId: number, options?: RawAxiosRequestConfig) {
        return SampleApiFp(this.configuration).getLast40DaysSamples(stationId, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * SpotsApi - axios parameter creator
 * @export
 */
export const SpotsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Add a new private Spot.
         * @param {AddPrivateSpotRequest} addPrivateSpotRequest Add a new private Spot.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addPrivateSpot: async (addPrivateSpotRequest: AddPrivateSpotRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'addPrivateSpotRequest' is not null or undefined
            assertParamExists('addPrivateSpot', 'addPrivateSpotRequest', addPrivateSpotRequest)
            const localVarPath = `/spots/add`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(addPrivateSpotRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Remove a private Spot.
         * @param {string} spotId The id of the spot to delete.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deletePrivateSpot: async (spotId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'spotId' is not null or undefined
            assertParamExists('deletePrivateSpot', 'spotId', spotId)
            const localVarPath = `/spots/delete/{spotId}`
                .replace(`{${"spotId"}}`, encodeURIComponent(String(spotId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Public and Private SpotInformation-List including Current Sample and Forecast for logged in user.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllSpots: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/spots/all`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get SpotInformation-List including Current Sample and Forecast for non-logged-in user.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPublicSpots: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/spots/public`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Change the order of the spots
         * @param {Array<string>} requestBody The new order of the spots.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orderSpots: async (requestBody: Array<string>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'requestBody' is not null or undefined
            assertParamExists('orderSpots', 'requestBody', requestBody)
            const localVarPath = `/spots/order`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SpotsApi - functional programming interface
 * @export
 */
export const SpotsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = SpotsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Add a new private Spot.
         * @param {AddPrivateSpotRequest} addPrivateSpotRequest Add a new private Spot.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addPrivateSpot(addPrivateSpotRequest: AddPrivateSpotRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addPrivateSpot(addPrivateSpotRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SpotsApi.addPrivateSpot']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Remove a private Spot.
         * @param {string} spotId The id of the spot to delete.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deletePrivateSpot(spotId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deletePrivateSpot(spotId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SpotsApi.deletePrivateSpot']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get Public and Private SpotInformation-List including Current Sample and Forecast for logged in user.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllSpots(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiSpotInformationList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAllSpots(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SpotsApi.getAllSpots']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get SpotInformation-List including Current Sample and Forecast for non-logged-in user.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPublicSpots(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiSpotInformationList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPublicSpots(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SpotsApi.getPublicSpots']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Change the order of the spots
         * @param {Array<string>} requestBody The new order of the spots.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async orderSpots(requestBody: Array<string>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.orderSpots(requestBody, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SpotsApi.orderSpots']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * SpotsApi - factory interface
 * @export
 */
export const SpotsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = SpotsApiFp(configuration)
    return {
        /**
         * 
         * @summary Add a new private Spot.
         * @param {AddPrivateSpotRequest} addPrivateSpotRequest Add a new private Spot.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addPrivateSpot(addPrivateSpotRequest: AddPrivateSpotRequest, options?: any): AxiosPromise<void> {
            return localVarFp.addPrivateSpot(addPrivateSpotRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Remove a private Spot.
         * @param {string} spotId The id of the spot to delete.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deletePrivateSpot(spotId: string, options?: any): AxiosPromise<void> {
            return localVarFp.deletePrivateSpot(spotId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Public and Private SpotInformation-List including Current Sample and Forecast for logged in user.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllSpots(options?: any): AxiosPromise<ApiSpotInformationList> {
            return localVarFp.getAllSpots(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get SpotInformation-List including Current Sample and Forecast for non-logged-in user.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPublicSpots(options?: any): AxiosPromise<ApiSpotInformationList> {
            return localVarFp.getPublicSpots(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Change the order of the spots
         * @param {Array<string>} requestBody The new order of the spots.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orderSpots(requestBody: Array<string>, options?: any): AxiosPromise<void> {
            return localVarFp.orderSpots(requestBody, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * SpotsApi - object-oriented interface
 * @export
 * @class SpotsApi
 * @extends {BaseAPI}
 */
export class SpotsApi extends BaseAPI {
    /**
     * 
     * @summary Add a new private Spot.
     * @param {AddPrivateSpotRequest} addPrivateSpotRequest Add a new private Spot.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SpotsApi
     */
    public addPrivateSpot(addPrivateSpotRequest: AddPrivateSpotRequest, options?: RawAxiosRequestConfig) {
        return SpotsApiFp(this.configuration).addPrivateSpot(addPrivateSpotRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Remove a private Spot.
     * @param {string} spotId The id of the spot to delete.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SpotsApi
     */
    public deletePrivateSpot(spotId: string, options?: RawAxiosRequestConfig) {
        return SpotsApiFp(this.configuration).deletePrivateSpot(spotId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Public and Private SpotInformation-List including Current Sample and Forecast for logged in user.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SpotsApi
     */
    public getAllSpots(options?: RawAxiosRequestConfig) {
        return SpotsApiFp(this.configuration).getAllSpots(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get SpotInformation-List including Current Sample and Forecast for non-logged-in user.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SpotsApi
     */
    public getPublicSpots(options?: RawAxiosRequestConfig) {
        return SpotsApiFp(this.configuration).getPublicSpots(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Change the order of the spots
     * @param {Array<string>} requestBody The new order of the spots.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SpotsApi
     */
    public orderSpots(requestBody: Array<string>, options?: RawAxiosRequestConfig) {
        return SpotsApiFp(this.configuration).orderSpots(requestBody, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * UserApi - axios parameter creator
 * @export
 */
export const UserApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Register new User.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        registerUser: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/user/register`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UserApi - functional programming interface
 * @export
 */
export const UserApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = UserApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Register new User.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async registerUser(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.registerUser(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UserApi.registerUser']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * UserApi - factory interface
 * @export
 */
export const UserApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = UserApiFp(configuration)
    return {
        /**
         * 
         * @summary Register new User.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        registerUser(options?: any): AxiosPromise<void> {
            return localVarFp.registerUser(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * UserApi - object-oriented interface
 * @export
 * @class UserApi
 * @extends {BaseAPI}
 */
export class UserApi extends BaseAPI {
    /**
     * 
     * @summary Register new User.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public registerUser(options?: RawAxiosRequestConfig) {
        return UserApiFp(this.configuration).registerUser(options).then((request) => request(this.axios, this.basePath));
    }
}



