// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.form {
  background-color: #efefef;
  display: flex;
  justify-content: center;
  align-content: center;
  width: 100%;
  align-items: center;
  height: 100vh;
  flex-direction: column;
}

.box-container {
  width: 50%;
  margin: auto;
}
@media screen and (max-width: 719px) {
  .box-container {
    width: 90%;
  }
}

.box {
  width: 100%;
  border: 1px solid #dfdfdf;
  background-color: #fff;
}

.google-button-container > div {
  margin: auto;
}`, "",{"version":3,"sources":["webpack://./src/user/user.scss","webpack://./src/ReactiveMixins.scss"],"names":[],"mappings":"AAEA;EACE,yBAAA;EACA,aAAA;EACA,uBAAA;EACA,qBAAA;EACA,WAAA;EACA,mBAAA;EACA,aAAA;EACA,sBAAA;AADF;;AAIA;EACE,UAAA;EACA,YAAA;AADF;ACPE;EDMF;IAKI,UAAA;EAAF;AACF;;AAGA;EACE,WAAA;EACA,yBAAA;EACA,sBAAA;AAAF;;AAGA;EACE,YAAA;AAAF","sourcesContent":["@import \"../ReactiveMixins\";\n\n.form {\n  background-color: #efefef;\n  display: flex;\n  justify-content: center;\n  align-content: center;\n  width: 100%;\n  align-items: center;\n  height: 100vh;\n  flex-direction: column;\n}\n\n.box-container {\n  width: 50%;\n  margin: auto;\n\n  @include small {\n    width: 90%;\n  }\n}\n\n.box {\n  width: 100%;\n  border: 1px solid #dfdfdf;\n  background-color: #fff;\n}\n\n.google-button-container > div {\n  margin: auto;\n}","$screen-medium-min: 720px;\n$screen-large-min: 1000px;\n\n$screen-small-max: $screen-medium-min - 1;\n$screen-medium-max: $screen-large-min - 1;\n\n@mixin small {\n  @media screen and (max-width: $screen-small-max) {\n    @content;\n  }\n}\n\n@mixin medium {\n  @media screen and (min-width: $screen-medium-min) and (max-width: $screen-medium-max) {\n    @content;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
