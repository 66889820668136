// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../public/ripples-snip.jpg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.App-header {
  font-size: 4vmin;
  color: #000000;
}
@media screen and (max-width: 719px) {
  .App-header {
    margin-top: 50px;
  }
}

.title > h1 {
  text-transform: uppercase;
  font-weight: 800;
}

.title {
  font-family: Arial, sans-serif;
  background-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
  background-repeat: repeat;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

h1 {
  margin-block-end: 0;
}

p {
  font-size: 92%;
  margin-block-start: 0;
}

.loginOrLogoutContainer {
  display: flex;
  width: 200px;
  position: absolute;
  top: 0;
  right: 0;
  justify-content: right;
}

.login, .logout, .signup, .add-spot {
  margin-left: 0;
  flex: 1 1;
}`, "",{"version":3,"sources":["webpack://./src/header/MswHeader.scss","webpack://./src/ReactiveMixins.scss"],"names":[],"mappings":"AAEA;EACE,gBAAA;EACA,cAAA;AADF;ACIE;EDLF;IAKI,gBAAA;EAAF;AACF;;AAGA;EACE,yBAAA;EACA,gBAAA;AAAF;;AAGA;EACE,8BAAA;EACA,yDAAA;EACA,yBAAA;EACA,6BAAA;EACA,oCAAA;AAAF;;AAGA;EACE,mBAAA;AAAF;;AAGA;EACE,cAAA;EACA,qBAAA;AAAF;;AAGA;EACE,aAAA;EACA,YAAA;EACA,kBAAA;EACA,MAAA;EACA,QAAA;EACA,sBAAA;AAAF;;AAGA;EACE,cAAA;EACA,SAAA;AAAF","sourcesContent":["@import \"../ReactiveMixins\";\n\n.App-header {\n  font-size: 4vmin;\n  color: #000000;\n\n  @include small() {\n    margin-top: 50px;\n  }\n}\n\n.title > h1 {\n  text-transform: uppercase;\n  font-weight: 800;\n}\n\n.title {\n  font-family: Arial, sans-serif;\n  background-image: url(../../public/ripples-snip.jpg);\n  background-repeat: repeat;\n  -webkit-background-clip: text;\n  -webkit-text-fill-color: transparent;\n}\n\nh1 {\n  margin-block-end: 0;\n}\n\np {\n  font-size: 92%;\n  margin-block-start: 0;\n}\n\n.loginOrLogoutContainer {\n  display: flex;\n  width: 200px;\n  position: absolute;\n  top: 0;\n  right: 0;\n  justify-content: right;\n}\n\n.login, .logout, .signup, .add-spot {\n  margin-left: 0;\n  flex: 1;\n}","$screen-medium-min: 720px;\n$screen-large-min: 1000px;\n\n$screen-small-max: $screen-medium-min - 1;\n$screen-medium-max: $screen-large-min - 1;\n\n@mixin small {\n  @media screen and (max-width: $screen-small-max) {\n    @content;\n  }\n}\n\n@mixin medium {\n  @media screen and (min-width: $screen-medium-min) and (max-width: $screen-medium-max) {\n    @content;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
